<!-- 
author:张洪志
description: 基金详情-交易规则
 -->
<template>
  <section class="fund-trade-rule" title="交易规则" v-if="show">
    <div class="flex-between" style="margin-bottom: 30px;">
      <div class="title">交易规则</div>
      <span class="close btn ico-x" @click="close"></span>
    </div>
    <div class="flex-between">
      <div class="tab">
        <div :class="['btn', { current: tabIndex === 1 }]" @click="tabIndex = 1">买入规则</div>
        <div :class="['btn', { current: tabIndex === 2 }]" @click="tabIndex = 2">卖出规则</div>
        <!-- <div :class="['btn',{'current':tabIndex===3}]" @click="tabIndex=3">转换规则</div> -->
      </div>

      <div class="pannel" title="买入规则" v-show="tabIndex === 1">
        <div class="title" style="margin-top:0;">
          <span class="ico ico-block"></span>
          <span class="txt">买入流程</span>
        </div>
        <div>
          <img src="../../../static/img/fund-market/detail_1_1.png" />
          <div class=" small timeline">
            <span>今日15点前</span>
            <span style="margin:0 180px 0 85px;">T + {{tradeRule.buyConfirmDate}}</span>
            <span>T + {{tradeRule.buyConfirmDate}} 净值更新后</span>
          </div>
        </div>
        <div class="title">
          <span class="ico ico-block"></span>
          <span class="txt">买入费率</span>
        </div>
        <div class="table table-2">
          <dl class="flex-between">
            <dt>适用金额</dt>
            <dd>买入费率</dd>
          </dl>
          <dl class="flex-between" v-for="(item, index) in subRate" :key="index">
            <dt>{{ item.name }}</dt>
            <dd>
              <s v-if="false">1.50%</s>
              <span class="orange">{{ item.value }}</span>
            </dd>
          </dl>
        </div>
        <div class="title">
          <span class="ico ico-block"></span>
          <span class="txt">买入规则</span>
        </div>
        <div class="table table-2">
          <dl class="flex-between">
            <dt>买入状态</dt>
            <dd>{{ tradeRule.subSts }}</dd>
          </dl>
          <dl class="flex-between">
            <dt>买入起点</dt>
            <dd>{{ tradeRule.subStartAmt }}元</dd>
          </dl>
          <dl class="flex-between">
            <dt>定投起点</dt>
            <dd>{{ tradeRule.autoInvStartAmt }}元</dd>
          </dl>
          <dl class="flex-between">
            <dt>持仓上限</dt>
            <dd>{{ tradeRule.positionUperLimt }}</dd>
          </dl>
          <dl class="flex-between">
            <dt>追加起点</dt>
            <dd>{{ tradeRule.additionalStartAmt }}元</dd>
          </dl>
          <dl class="flex-between">
            <dt>转出份额</dt>
            <dd>{{ tradeRule.minimumShare }}份</dd>
          </dl>
        </div>
        <div class="title">
          <span class="ico ico-block"></span>
          <span class="txt">运作费用</span>
        </div>
        <div class="table table-2">
          <dl class="flex-between">
            <dt>认/申购费用计算方式</dt>
            <dd>{{ tradeRule.subFormulaMode }}</dd>
          </dl>
          <dl class="flex-between">
            <dt>销售服务费率</dt>
            <dd>{{ saleRate }}</dd>
          </dl>
          <dl class="flex-between">
            <dt>基金管理费率</dt>
            <dd>{{ managerRate }}</dd>
          </dl>
          <dl class="flex-between">
            <dt>基金托管费率</dt>
            <dd>{{ trusteeRate }}</dd>
          </dl>
        </div>
        <p class="small gray notice">管理费和托管费从基金资产中每日计提。每个工作日公告的基金净值已扣除管理费和托管费，无需投资者在每笔交易中另行支付</p>
      </div>
      <div class="pannel" title="卖出规则" v-show="tabIndex === 2">
        <div class="title" style="margin-top:0;">
          <span class="ico ico-block"></span>
          <span class="txt">卖出流程</span>
        </div>
        <div>
          <p><img src="../../../static/img/fund-market/detail_2_1.png" /></p>
          <p class="small timeline">
            <span>今日15点前</span>
            <span style="margin:0 120px 0 70px;">确认金额</span>
            <span>T + {{tradeRule.fundTransferDate}} 以实际银行到账为准</span>
          </p>
          <p><img src="../../../static/img/fund-market/detail_2_2.png" /></p>
          <p class="small timeline">
            <span>今日15点前</span>
            <span style="margin:0 120px 0 70px;">确认金额</span>
            <span>T + {{tradeRule.fundTransferDate}} 以实际银行到账为准</span>
          </p>
        </div>
        <div class="title">
          <span class="ico ico-block"></span>
          <span class="txt">卖出费率</span>
        </div>
        <div class="table table-2">
          <dl class="flex-between">
            <dt>持有日期</dt>
            <dd>卖出费率</dd>
          </dl>
          <dl class="flex-between" v-for="(item, index) in redeemRate" :key="index">
            <dt>{{ item.name }}</dt>
            <dd>
              <s v-if="false">1.50%</s>
              <span class="orange">{{ item.value }}</span>
            </dd>
          </dl>
        </div>
        
        <div class="title">
          <span class="ico ico-block"></span>
          <span class="txt">卖出规则</span>
        </div>
        <div class="table table-2">
          <dl class="flex-between">
            <dt>买入状态</dt>
            <dd>{{ tradeRule.subSts }}</dd>
          </dl>
          <dl class="flex-between">
            <dt>买入起点</dt>
            <dd>{{ tradeRule.subStartAmt }}元</dd>
          </dl>
          <dl class="flex-between">
            <dt>定投起点</dt>
            <dd>{{ tradeRule.autoInvStartAmt }}元</dd>
          </dl>
          <dl class="flex-between">
            <dt>持仓上限</dt>
            <dd>{{ tradeRule.positionUperLimt }}</dd>
          </dl>
          <dl class="flex-between">
            <dt>追加起点</dt>
            <dd>{{ tradeRule.additionalStartAmt }}元</dd>
          </dl>
          <dl class="flex-between">
            <dt>转出份额</dt>
            <dd>{{ tradeRule.minimumShare }}份</dd>
          </dl>
          <dl class="flex-between">
            <dt>极速回惠金宝</dt>
            <dd>{{ tradeRule.fastBackSuper }}</dd>
          </dl>
          <dl class="flex-between">
            <dt>基金转换</dt>
            <dd>{{ tradeRule.fundChangeFlag }}</dd>
          </dl>
        </div>
      </div>
      <!-- <div class="pannel" title="转换规则" v-show="tabIndex===3"></div> -->
    </div>
  </section>
</template>

<script>
import { getDicts } from '@/api/dict.js';
import { fundTradeRule } from '@/api/fund.js';
export default {
  name: 'FundDetailRule',
  props: {
    fundCode: String
  },
  data() {
    return {
      show: false,
      tabIndex: 1,
      tradeRule: {},
      subPreRate: [],
      redeemRate: [],
      saleRate: '',
      managerRate: '',
      trusteeRate: '',

      prod_subSts: [],
      quickly_huichaobaos: [],
      prod_subFormulaModes: [],
      prod_change_flags: [],

      date: null
    }
  },
  created() {
    let date = new Date();
    date.$confirmDate();
    this.date = date;

    getDicts('prod_subSts').then(result => {
      this.prod_subSts = Array.from(result.data, m => ({ name: m.dictLabel, code: m.dictValue }));
      getDicts('quickly_huichaobao').then(result2 => {
        this.quickly_huichaobaos = Array.from(result2.data, m => ({ name: m.dictLabel, code: m.dictValue }));
      });
      getDicts('prod_subFormulaMode').then(result3 => {
        this.prod_subFormulaModes = Array.from(result3.data, m => ({ name: m.dictLabel, code: m.dictValue }));
      });
      getDicts('prod_trage_change').then(result4 => {
        this.prod_change_flags = Array.from(result4.data, m => ({ name: m.dictLabel, code: m.dictValue }));
      });
      this.getDetail();
    });
  },
  methods: {
    getDetail() {
      fundTradeRule(this.fundCode).then(result => {
        let tradeRule = result.data.prodTradeRule || {};
        tradeRule.subSts = (this.prod_subSts.find(m => m.code === tradeRule.subSts) || {}).name;
        tradeRule.fastBackSuper = (this.quickly_huichaobaos.find(m => m.code === tradeRule.fastBackSuper) || {}).name;
        tradeRule.subFormulaMode = (this.prod_subFormulaModes.find(m => m.code === tradeRule.subFormulaMode) || {}).name;
        tradeRule.fundChangeFlag = (this.prod_change_flags.find(m => m.code === tradeRule.fundChangeFlag) || {}).name;
        this.tradeRule = tradeRule;
        let subRate = result.data.subRate || [];
        this.subRate = Array.from(subRate, m => {
          let _data = m.split(' ');
          let value = _data[1]
          /* 
          let _value = parseFloat(value)
          value = _value < 10 ? value + ' %' : value + ' 元'
          */
          return {
            name: _data[0],
            value
          };
        });
        let redeemRate = result.data.redeemRate || [];
        this.redeemRate = Array.from(redeemRate, m => {
          let _data = m.split(' ');
          let value = _data[1]
          /* 
          let _value = parseFloat(value)
          value = _value < 10 ? value + ' %' : value + ' 元'
          */
          return {
            name: _data[0],
            value
          };
        });
        
        if(result.data.saleRate && result.data.saleRate.length>0) {
          this.saleRate = result.data.saleRate[0]
        }
        if(result.data.managerRate && result.data.managerRate.length>0) {
          this.managerRate = result.data.managerRate[0]
        }
        if(result.data.trusteeRate && result.data.trusteeRate.length>0) {
          this.trusteeRate = result.data.trusteeRate[0]
        }
      });
    },
    close() {
      this.show = false;
    }
  }
};
</script>

<style lang="less" scoped>
@gray: #bbb;
@orange: #cc9331;
@gray-bg: #f3f4f6;

.gray {
  color: @gray;
}

.orange {
  color: @orange;
}

.gray-bg {
  background: @gray-bg;
}

.small {
  font-size: 12px;
}

.fund-trade-rule {
  width: 940px;
  padding: 30px;
  position: relative;
  margin: 5% auto;
  background: #fff;
  box-shadow: 0 0 10px #ddd;
}

.title {
  font: bold 18px 'microsoft yahei';
}

.tab {
  width: 150px;
  box-shadow: 0 0 10px #eee;
  padding: 30px 10px;
  text-align: center;
  font: bold 16px/3.3em 'microsoft yahei';

  & > div {
    margin-bottom: 1em;

    &.current {
      background: #eee;
      .orange();
      border-radius: 5px;
    }
  }
}

.pannel {
  width: 725px;

  .title {
    font: 19px 'microsoft yahei';
    margin: 1.5em auto;
    .ico-block{
      .orange(); margin-right: 5px;
    }
  }
}

.timeline {
  line-height: 2em;
}

.table {
  line-height: 3em;
  font-size: 15px;
  border: 1px solid #eee;
  border-width: 1px 1px 0 0;

  dl {
    border-bottom: 1px solid #eee;
  }

  dt {
    .gray-bg();
    padding: 0 1em;
  }

  dd {
    padding-left: 1em;

    s {
      margin-right: 10px;
    }
  }
}

.table-2 {
  dt {
    width: 35%;
    text-align: right;
    padding-right: 1.5em;
  }

  dd {
    width: 56%;
  }
}

.table-4 {
  dt {
    width: 6em;
    text-align: center;
  }

  dd {
    width: 220px;
  }
}

.notice {
  margin-top: 1.5em;
}
</style>
